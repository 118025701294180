@import 'swiper/css/bundle';
@import 'app/theme/theme';
@import "src/app/theme/variables";

html {
  font-size: 16px;

  @media screen and (max-width: 1280px) {
    font-size: 10px;
  }

  overflow: hidden
}

body {
  margin: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  position: fixed;
  background-color: $app-background;
  color: $lighter-color;
}

* {
  box-sizing: border-box;
}