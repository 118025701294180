//DETAIL COMPONENT

@mixin pos-detail ($height-detail, $top-detail) {
  left: 58vw;
  top: $top-detail;
  padding-right: 6rem;
  padding-top: 6rem;
  position: absolute;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  height: $height-detail;
}


//BUTTON COMPONENT SETTINGS

@mixin set-button-style ($button-background-gradient , $button-background-color, $button-border, $button-margin-left) {
  background: $button-background-gradient;
  background-color: $button-background-color;
  border: $button-border;

  .content {
    span {
      margin-left: $button-margin-left;
    }
  }
}

@mixin focusing {
  @at-root .mode-cursor &:hover, .mode-keyboard &:focus {
    @content;
  }
}