@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@500;600;700;800;900&display=swap');


:root {
    /* Define a GENERAL Typographies settings */
    --ff-primary: Poppins, Helvetica, sans-serif;
    --ff-secondary: Raleway, Helvetica, sans-serif;
    --ff-titles: Raleway, Helvetica, sans-serif;
    --ff-static-component: Poppins, Helvetica, sans-serif;

    /* Define a HEADERS LEVEL 2 Typographies settings */
    --ff-title: Poppins, Helvetica, sans-serif;

    /* Define KEYBOARD font */
    --ff-keyboard: Poppins, Helvetica, sans-serif;
    --fs-keyboard: 1.875rem;

    /* Define a GENERAL WEIGHT TYPOGRAPHY settings */
    --fw-regular: 400;
    --fw-medium: 500;
    --fw-semibold: 600;
    --fw-bold: 700;
    --fw-extrabold: 800;
    --fw-titles: 800;

    /* Define a GENERAL SIZE TYPOGRAPHY settings */
    --fs-extra-small: 0.625rem;
    --fs-small: 0.78125rem;
    --fs-normal: 1rem;
    --fs-medium: 1.25rem;
    --fs-large: 1.563rem;
    --fs-extra-large: 1.875rem;
    --fs-medium-large-title: 2.5rem;
    --fs-extra-large-title: 3.125rem;

    /* Define a LINE HEIGHT: */
    --lh-small: 1.2em;
    --lh-medium: 1.6em;
    --lh-large: 2em;

    /* Define RETURN BUTTON FONT */
    --fs-return-button: 1.375rem;

    /* Define LOGIN PAGE FONTS */
    --fs-code-url: 2.5rem;
    --fs-login-code: 5rem;
    --fs-button-medium: 1.5625rem;
    --fs-button-large: 1.875rem;
    --fs-login-large: 1.5625rem;
    --fs-login-medium: 1.375rem;

    /* Define ENTERPRISE TITLE */
    --enterprise-title-wrap: nowrap;
}
