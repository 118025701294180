@import "variables";
@import "mixins";
@import "./styles/theo";
@import "./styles/timeline";
@import "./styles/buttons";
@import "./styles/styles_pages/home";
@import "./styles/styles_pages/login";
@import "./styles/styles_pages/seasons";


body {
  font-family: $ff-primary;
  background-color: $app-background;
}

// GENERAL STYLES

h1 {
  font-family: $ff-titles;
  font-size: $fs-extra-large-title;
  font-weight: $fw-titles;
  line-height: $fs-extra-large-title;
}

h2 {
  font-family: $ff-titles;
  font-size: $fs-medium-large-title;
  font-weight: $fw-titles;
  line-height: $fs-medium-large-title;
}

h3 {
  font-family: $ff-titles;
  font-size: $fs-extra-large;
  font-weight: $fw-titles;
  line-height: $fs-extra-large;
}

//CSS FOR PAGES TITLE (MySpaceComponent and SearchComponent)
h4 {
  font-family: $ff-primary;
  font-size: $fs-large;
  font-weight: $fw-bold;
  line-height: $fs-extra-large;
  margin: 0rem;
  padding-bottom: 3.5rem;
}

//CSS FOR SIDE MENU STATUS//
#side-menu {

  //SIDE MENU NOT IN FOCUS//
  .menu:not(:focus) {
    width: 2vw;
  }

  .menu-closed {
    //// MINIMIZED MENU STYLES

    .logo-full-link-minimized {
      .logo-wrapper {
        .title-content {
          h3 {
            opacity: 0;
            display: none;
          }

          img {
            height: 3.625rem;
            max-width: 4rem;
          }
        }
      }
    }

    .menu-item-minimized {
      background-color: transparent;
      overflow: hidden;
      width: 6vw;

      .menu-icon {
        opacity: 1;
      }

      span {
        opacity: 0;
        pointer-events: none;
        display: none;
        overflow: hidden;
      }
    }

    //// HIDDEN MENU STYLES
    .logo-full-link-hidden {
      .logo-wrapper {
        .title-content {
          width: fit-content;

          h3 {
            opacity: 0;
            display: none;
          }

          img {
            max-width: 12rem;
          }
        }
      }
    }

    .menu-item-hidden {
      background-color: transparent;
      opacity: 0;

      .menu-icon {
        opacity: 0;
      }

      h3 {
        opacity: 0;
      }
    }
  }

  //SIDE MENU IN FOCUS AND OPEN//
  .menu-opened {
    width: 17vw;

    .logo-full-link {
      .logo-wrapper {
        .title-content {
          img {
            max-width: 4rem;

          }

          .logoMenuFullWidth {
            max-width: 12rem;
          }
        }
      }
    }

    .menu-items-wrapper {
      left: 0;
    }

    #menu-background {
      background-color: $sidemenu-background-color;
      border-right: $sidemenu-border-right-size;
      left: 0; //SET POSITION INSIDE THE STAGE
    }
  }
}

//CSS FOR PLAYER COMPONENT //
ty-player {
  //CSS FOR HEADER PLAYER COMPONENT //

  .header-player {
    padding-left: 2.375rem;
    padding-top: 2.375rem;
    padding-right: 4.375rem;

    .logo-player {
      .title-content {
        padding-left: 0rem; //SET TITLE CONTENT POSITION

        img {
          height: 5rem;
          max-width: 12rem;
        }
      }
    }

    .return-button {
      #return-button {
        font-size: $fs-return-button;
        font-weight: $fw-regular;

        .text {
          padding-left: 0.2rem;
        }
      }
    }
  }

  //CSS FOR PLAYER CONTROLS //
  .plyr {
    width: 100%;
    height: 100%;
    display: flex;

    .plyr__controls {
      width: 45vw;
      bottom: 10vh;
      margin: 0 auto;
      background: none;
      background-color: none;
      z-index: 1;
      position: absolute;

      .plyr__time {
        font-size: $fs-medium;
        font-weight: $fw-regular;
      }

      .plyr__controls__item.plyr__progress__container {
        .plyr__progress {
          .plyr__progress__buffer {
            color: rgba(0, 0, 0, 0.7);
            mix-blend-mode: multiply;
          }
        }
      }
    }
  }

  .plyr--full-ui input[type="range"] {
    color: rgba(255, 255, 255, 0.7);
  }

  .plyr--full-ui input[type="range"]:hover {
    color: $primary-color;
  }

  .plyr--full-ui input[type="range"]:focus {
    color: $primary-color;
    outline: none;
  }
}

// SEASON SELECTOR COMPONENT
#season-container {
  @include focusing {
    outline: none;

    .season {
      border: solid 2px $primary-color;
      border-radius: 1.8rem;
      outline: 5px solid none;
    }
  }
}

// CHAPTER SELECTOR COMPONENT

.chapters {
  #selector {
    .selector-wrapper {
      ty-chapter {
        @include focusing {
          border: none;

          span {
            color: $lighter-color;
          }

          .underline-active-item {
            opacity: 1;
          }

          .info {
            .duration {
              color: $primary-color;
            }
          }

          .thumb {
            img {
              height: 100%;
              filter: brightness(1);
            }
          }

          .icon {
            filter: none;
          }
        }
      }
    }
  }
}


//CSS FOR GRID COMPONENT
#my-space,
#search,
#see-more,
#genres,
#purchases,
#collections {
  .grid-overflow-hidden {
    .grid {
      #grid {
        margin-left: 0;
      }
    }
  }
}

#grid {
  .grid-wrapper {
    .grid-container {
      display: flex;
      flex-direction: column;
      margin-bottom: 2rem;


      .underline-active-item {
        width: 100%;
        margin-top: 0.9375rem;
        border-bottom: 0.625rem solid transparent;
        border-radius: $hc-focus-border-radius;
        background-image: $item-border-bottom-background, $item-border-bottom-background;
        background-origin: border-box;
        background-clip: content-box, border-box;
        opacity: 0;
        -webkit-transform: scale(1, 1);
        transform: scale(1, 1);
        -webkit-transition: all 0.2s;
        transition: all 0.2s;
      }

      .co-grid-thumb {
        -webkit-transform: scale(1, 1);
        transform: scale(1, 1);
        -webkit-transition: all 0.2s;
        transition: all 0.2s;

        #grid-thumb {
          .thumb {
            filter: brightness(1); //SET FULL OPACITY OF THUMBNAIL IMAGE
            transition: filter 0.2s;
          }

          h3 {
            opacity: 0;
            position: relative;
            margin-top: 60%;
            text-align: left;
            padding: 0.8rem;
            text-shadow: $ts-detail;
          }

          .show-grid-text {
            display: block;
            opacity: 1;
          }
        }

        #landscape-grid-thumb {
          .thumb {
            filter: brightness(1); //SET FULL OPACITY OF THUMBNAIL IMAGE
            transition: filter 0.2s;
          }

          h3 {
            position: absolute;
            margin-top: 63%;
            text-align: left;
            padding: 0.8rem;
            text-shadow: $ts-detail;
          }

          .show-grid-text {
            display: block;
            opacity: 1;
          }
        }

        @include focusing {
          outline: none;
          cursor: pointer;
          -webkit-transform: scale(1.02, 1.02);
          transform: scale(1.02, 1.02);

          #grid-thumb {
            .thumb--focused {
              filter: $hc-thumb-grid-filter; //SET DARK OPACITY OF THUMBNAIL GRID IMAGE
            }

            .info {
              .metadata {
                .tag {
                  min-width: 2rem;

                  .grid-text {
                    opacity: 1 !important;
                    display: block;
                  }
                }
              }
            }

            h3 {
              opacity: 1;
            }
          }

          #landscape-grid-thumb {
            .focused-thumb {
              border: 0.25rem solid $lighter-color;
            }

            .info {
              .metadata {
                .tag {
                  min-width: 2rem;

                  .grid-text {
                    opacity: 1 !important;
                    display: block;
                  }
                }
              }
            }
          }

          &+div.underline-active-item {
            margin-top: 0.9375rem;
            opacity: 1; //SET PRIMARY COLOR FOCUS UNDERLINE
            -webkit-transform: scale(1.02, 1.02);
            transform: scale(1.02, 1.02);
          }

          //CSS FOR REPOSITION RIBBON COMPONENT
          .ribbon {
            height: 2.971875rem;
          }

        }
      }
    }
  }
}