//SEASONS
.selected-season {
  border: $primary-button-border-hover;
  border-radius: 1.8rem;
  background: $primary-button-bg-color-hover;
}

.season {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 3.438rem;
  width: 100%;
  padding: 1rem;
  font-size: $fs-medium;
  justify-content: center;
  border: solid 2px transparent;

  @include focusing {
    border: solid 2px $primary-button-text-color-hover;
  }

  &.selected-season {
    color: $primary-button-text-color-hover;

    .season-number {
      border-right: 2px solid $primary-button-text-color-hover;
    }
  }

  span {
    margin: 0 1rem 0 0;
    font-size: 1.25rem;
  }

  .title {
    display: flex;
    justify-content: flex-end;
  }

  .season-number {
    height: 100%;
    width: 4rem;
    display: flex;
    border-right: 2px solid $lighter-color-semi;
    justify-content: center;
    align-items: center;
  }

  .chapters {
    display: flex;
    justify-content: flex-start;
  }

  .selected {
    opacity: 1;
    transition: all 0.2s;
  }
}

//CHAPTERS
#selector {
  height: auto;
  overflow: hidden;

  .selector-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;

  }
}

.chapter {

  &:focus-visible {
    outline: none;
  }

  @include focusing {
    .info {
      .number-of-chapter {
        color: $description-color;
      }

      .title {
        color: $lighter-color;
      }

      .duration {
        color: $description-color;
      }

      .description {
        color: $lighter-color;
      }
    }

    .thumb {
      img {
        filter: none;
      }
    }

    .icon {
      filter: none;
    }

    .underline-active-item {
      opacity: 1;
    }

  }

  height: 13rem;
  display: flex;
  margin-bottom: 2rem;

  .thumb {
    img {
      background-color: $neutral-moderate-surface;
      border-radius: $hc-thumb-border-radius;
      height: 12.5rem;
      width: 21.875rem;
      object-fit: cover;
      filter: $hc-thumb-filter;
    }
  }

  .underline-active-item {
    -webkit-transform: scale(1, 1);
    -webkit-transition: all 0.2s;
    border-bottom: 0.625rem solid transparent;
    border-radius: $hc-focus-border-radius;
    background-image: $item-border-bottom-background,
      $item-border-bottom-background;
    background-origin: border-box;
    background-clip: content-box, border-box;
    margin-top: 0.2rem;
    opacity: 0;
    transform: scale(1, 1);
    transition: all 0.2s;
    width: 100%;
  }

  .info {
    width: 52rem;
    margin-left: 5rem;
    margin-right: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .number-of-chapter {
      font-size: $fs-medium;
      color: $description-color;
    }

    .title {
      font-size: $fs-extra-large;
      line-height: $lh-small;
      font-weight: bold;
      color: $description-color;
      padding-bottom: 1rem;
    }

    .duration {
      font-weight: bold;
      font-size: $fs-medium ;
      color: $description-color;
    }

    .description {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
      font-size: $fs-large;
      color: $description-color;
    }
  }

  .icon {
    height: 6.1rem;
    width: 6.1rem;
    align-self: center;
    filter: $hc-thumb-filter;
  }
}