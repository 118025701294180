@font-face {
    font-family: "galgo-icons";
    src: url("./galgo-icons.ttf?c7040df9c4f4505243230a75908c3847") format("truetype"),
url("./galgo-icons.woff?c7040df9c4f4505243230a75908c3847") format("woff"),
url("./galgo-icons.woff2?c7040df9c4f4505243230a75908c3847") format("woff2"),
url("./galgo-icons.eot?c7040df9c4f4505243230a75908c3847#iefix") format("embedded-opentype");
}

i[class^="gi-"]:before, i[class*=" gi-"]:before {
    font-family: galgo-icons !important;
    font-style: normal;
    font-weight: normal !important;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.gi {
  font-size: 1rem;
}

.gi-2xs {
  font-size: .625rem;
  line-height: .1rem;
  vertical-align: 0.225em;
}

.gi-xs {
  font-size: .75em;
  line-height: .08333em;
  vertical-align: 0.125em;
}
.gi-sm {
  font-size: .875em;
  line-height: .07143em;
  vertical-align: 0.05357em;
}

.gi-lg {
    font-size: 1.25em;
    line-height: .05em;
    vertical-align: -0.075em;
}

.gi-xl {
    font-size: 1.5em;
    line-height: .04167em;
    vertical-align: -0.125em;
}

.gi-2xl {
    font-size: 2em;
    line-height: .03125em;
    vertical-align: -0.1875em;
}

.gi-alert-error:before {
    content: "\f101";
}
.gi-backspace:before {
    content: "\f102";
}
.gi-collections-menu:before {
    content: "\f103";
}
.gi-collections:before {
    content: "\f104";
}
.gi-configuracion:before {
    content: "\f105";
}
.gi-cross:before {
    content: "\f106";
}
.gi-filled-lock-icon:before {
    content: "\f107";
}
.gi-genres:before {
    content: "\f108";
}
.gi-home:before {
    content: "\f109";
}
.gi-live:before {
    content: "\f10a";
}
.gi-my-favorites:before {
    content: "\f10b";
}
.gi-ppv:before {
    content: "\f10c";
}
.gi-purchases:before {
    content: "\f10d";
}
.gi-return-icon:before {
    content: "\f10e";
}
.gi-search:before {
    content: "\f10f";
}
.gi-show-pass:before {
    content: "\f110";
}
.gi-space:before {
    content: "\f111";
}
.gi-subscription:before {
    content: "\f112";
}
.gi-visibility-off:before {
    content: "\f113";
}
.gi-visibility:before {
    content: "\f114";
}
