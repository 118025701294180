//CSS FOR BUTTON COMPONENT IN VIDEO DETAIL & DETAIL COMPONENT //
.button-list {
  display: flex;
  justify-content: left;

  .button-component {
    display: flex;
    flex-shrink: 0;

    .button-wrapper {
      margin-right: 0.75rem;

      .button {
        height: 100%;
        border-radius: $br-60;
        color: $button-font-color;
        font-family: $ff-primary;
        font-size: $fs-large;
        font-weight: $fw-semibold;
        -webkit-transition: color 1s;
        transition: color 1s;
        background-color: $secondary-button-bg-color;
        padding: 0.75rem 1.5rem;

        .content {
          display: flex;
          align-items: center;
          justify-content: center;

          .icon {
            display: flex;
            justify-content: center;
            text-align: center;
            height: 1.8rem;
            width: 1.8rem;

            &--space {
              margin-right: 0.75rem;
            }
          }
        }

        @include set-button-style(none, $secondary-button-bg-color, none, none);
      }

      @include focusing {
        .button {
          @include set-button-style($button-detail-color-hover, none, none, none);
          color: $play-button-text-color-hover;
          cursor: pointer;
        }
      }
    }
  }
}

.primary-button {
  button {
    background: $primary-button-bg-color;
    color: $primary-button-text-color;
    border: $primary-button-border;
    border-radius: $br-60;
    outline: $primary-button-outline;
  }

  @include focusing {
    outline: none;

    button {
      font-weight: $fw-bold;
      background: $primary-button-bg-color-hover;
      color: $primary-button-text-color-hover;
      border: $primary-button-border-hover;
      outline: $primary-button-outline-hover;
      box-shadow: $primary-box-shadow-hover;
    }
  }
}

.secondary-button {
  button {
    background: $secondary-button-bg-color;
    color: $secondary-button-text-color;
    border: $secondary-button-border;
    border-radius: $br-60;
  }

  @include focusing {
    outline: none;

    button {
      font-weight: $fw-bold;
      background: $secondary-button-bg-color-hover;
      color: $secondary-button-text-color-hover;
      border: $secondary-button-border-hover;
      outline: $secondary-button-outline-hover;
      box-shadow: $secondary-box-shadow-hover;
    }
  }
}

.button-medium {
  font-family: $ff-primary;
  font-size: $fs-button-medium;
  font-weight: $fw-regular;
}

.button-large {
  font-family: $ff-primary;
  font-size: $fs-button-large;
  font-weight: $fw-regular;
}

.text-large {
  font-size: $fs-login-large;
  font-weight: $fw-regular;
}

.text-medium {
  font-size: $fs-login-medium;
  font-weight: $fw-regular;
}
