//BANNER
//CSS FOR HOME BANNER MYSWIPER SLIDER
#my-swiper {
  #home-banner {
    .co-background-thumbnail {
      .background-thumbnail-image {
        height: 29rem;
        width: 100%;
        border-radius: $hc-thumb-border-radius;
        background-position: center;
      }
    }

    #home-banner-detail-container {
      .detail {
        .metadata {
          ty-tag {
            .live {
              span {
                display: none;
              }
            }
          }
        }
      }
    }
  }

  .swiper-wrapper {
    .swiper-slide {
      .image {
        &::after {
          content: " ";
          height: 100%;
          width: 100%;
          position: absolute;
          top: 0;
          left: 0;
          background: $right-linear-gradient;
        }
      }
    }
  }

  .swiper-pagination,
  .swiper-pagination-horizontal {
    bottom: -26rem;
    right: 0;
    width: 100vw;
    text-align: right;
    padding-right: 2rem;
    position: relative;
  }

  .swiper-pagination-bullet {
    width: 0.875rem;
    height: 0.875rem;
    background-color: $lighter-color;
    margin-right: 0.9375rem;
    box-shadow: $ts-detail;
    opacity: 1;
    transition: background-color 0.2s;

    @include focusing {
      background-color: $secondary-color;
      cursor: pointer;
    }
  }

  .swiper-pagination-bullet-active {
    background-color: $primary-color;
  }
}


#home {
  min-height: 100%;
  width: 100%;
  position: relative;

  .home-banner-section {
    border-radius: $hc-thumb-border-radius;
    display: flex;
    flex-direction: column;
    font-size: $fs-extra-large-title;
    justify-content: center;
    position: absolute;
    z-index: 10;
    width: 100%;
  }

  .carousels-wrapper {
    height: 49.5vh;
    overflow: hidden;
    padding-left: 2.625rem;
    position: absolute;
    top: 34rem;
    width: 100vw;
    z-index: 0;
  }
}

//CSS FOR BUTTON COMPONENT IN HOME BANNER //
#home-banner {
  .co-background-thumbnail {
    .background-thumbnail-image {
      height: 100%;
      width: 100%;

      &::after {
        display: none;
      }
    }
  }

  .button-banner {
    font-weight: $fw-bold;
    background: $primary-button-bg-color-hover;
    color: $primary-button-text-color-hover;
    border: 0.125rem solid $primary-button-border-hover;
    outline: $primary-button-outline-hover;
  }
}

//CSS FOR CAROUSEL COMPONENT
#carousel {
  position: relative;
  height: 7.8125rem;
  transition: transform 0.15s linear;
  will-change: transform;
  width: 100vw;

  .carousel-wrapper {
    display: flex;
    flex-direction: row;

    .virtual-scroll {
      display: flex;
    }

    #carousel-item {
      margin-right: 0.625rem;

      .underline-active-item {
        width: 100%;
        margin-top: 0.6875rem;
        border-bottom: 0.625rem solid transparent;
        border-radius: $hc-focus-border-radius;
        background-image: $item-border-bottom-background, $item-border-bottom-background;
        background-origin: border-box;
        background-clip: content-box, border-box;
        opacity: 0;
        -webkit-transform: scale(1, 1);
        transform: scale(1, 1);
        -webkit-transition: all 0.2s;
        transition: all 0.2s;
      }

      .co-media-thumb {
        display: block;
        -webkit-transform: scale(1, 1);
        transform: scale(1, 1);
        -webkit-transition: all 0.2s;
        transition: all 0.2s;

        .thumb {
          filter: $hc-thumb-filter;
          transition: filter 0.2s;
        }

        .info {
          .metadata {
            .tag {
              .show-carousel-text {
                display: block;
                opacity: 1;
              }
            }
          }
        }

        @include focusing {
          outline: none;
          cursor: pointer;
          -webkit-transform: scale(1.02, 1.02);
          transform: scale(1.02, 1.02);

          .thumb {
            filter: brightness(1);
          }

          .info {
            .metadata {
              .tag {
                width: auto !important;

                .text {
                  opacity: 1 !important;
                }

                .carousel-text {
                  display: block;
                }
              }
            }
          }

          &+div.underline-active-item {
            opacity: 1;
            -webkit-transform: scale(1.02, 1.02);
            transform: scale(1.02, 1.02);
          }

          //CSS FOR REPOSITION RIBBON COMPONENT
          .ribbon {
            &:before {
              top: 2.465rem;
            }
          }
        }
      }
    }
  }

  .see-more {
    position: absolute;
    top: 4rem;
    width: 17.5rem;
    font-size: 1.3rem;
    text-align: center;
    font-weight: 600;
  }
}

//CSS FOR SMALL RIBBON COMPONENT IN CAROUSEL MEDIA THUMB
#media-thumb {
  background-color: $neutral-moderate-surface;
  border-radius: $hc-thumb-border-radius;

  .info {
    .metadata {
      .ribbon-wrapper {
        .ribbon {
          height: 2.5rem;
          line-height: 2.5rem;
          margin: 0rem 0rem 1rem 0rem;
          width: 2.5rem;

          p {
            font-size: $fs-large;
            padding-top: 0.2rem;
          }

          &:before {
            border-width: 0 1.25rem 0.5rem 1.25rem;
            top: 2.5rem;
          }
        }
      }
    }
  }
}

//CSS FOR BOTTOM BLACK GRADIENT OVERLAY

.black-gradient-overlay {
  background: $bottom-linear-gradient;
  height: 11.25rem;
  width: 100vw;
  content: " ";
  position: fixed;
  left: 0.25rem;
  bottom: 0;
  z-index: 2;
}
