//LOGIN PAGE
.spacer {
  margin-top: 5rem;
}

#login-content {
  text-align: center;
  width: 82vw;
  margin: 0 auto;
  height: 100vh;

  h1 {
    margin-top: 1.25rem;
    margin-bottom: 5rem;
  }

  button {
    padding: 1.625rem 3.75rem;
    margin-bottom: 0.625rem;
  }

  #login-methods {
    display: flex;
    margin-bottom: 1rem;

    .login-method {
      text-align: left;

      &-a {
        width: 55%;
      }

      &-b {
        width: 45%;
      }

      p {
        margin-top: 0rem;
        margin-bottom: 1.25rem;
      }

      .title-method {
        display: flex;
        align-items: center;
        font-weight: $fw-bold;
        margin-bottom: 1.25rem;

        .icon-method {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 3.4375rem;
          height: 3.4375rem;
          border-radius: 50%;
          border: solid 2px $lighter-color;
          font-family: 'Poppins', sans-serif;
          font-size: $fs-extra-large;
          line-height: $fs-extra-large;
          margin-right: 1.125rem;
        }

        h4 {
          margin: 0rem;
          padding-bottom: 0rem;
        }
      }

      .content-method {
        padding-left: 4.6875rem;

        &-qr {
          display: flex;
          flex-direction: row;

          p {
            padding-left: 2rem;
            max-width: 40%;
          }
        }

        p {
          line-height: 2.1875rem;
          margin-bottom: 0rem;
        }

        h2.code-url {
          font-family: $ff-static-component;
          font-weight: $fw-bold;
          font-size: $fs-extra-large;
          margin-top: 1rem;
          margin-bottom: 3rem;
        }

        ul {
          display: flex;
          height: 6.5rem;
          list-style: none;
          width: fit-content;
          margin: 1.25rem 0rem 0rem 0rem;
          padding: 0rem;
          font-family: $ff-static-component;
          border-bottom: solid 2px $lighter-color;
          font-size: $fs-login-code;
          font-weight: $fw-extrabold;
          color: $primary-color;

          li {
            text-align: center;
            margin-right: 0.9375rem;
            line-height: $fs-login-code;
            text-shadow: $ts-detail;
          }
        }
      }
    }
  }

  #login-access-info {
    display: flex;
    flex-direction: column;
    padding-left: 4.6875rem;

    .buttons {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-bottom: 2rem;
      text-align: left;

      .primary-button {
        display: flex;
        align-items: center;
      }

      .box-space {
        min-height: 6rem;
      }

      button {
        margin-bottom: 0rem;
      }

      .mail {
        width: 55%;
      }

      .code {
        width: 45%;
        padding-left: 2.5rem;
      }

      p {
        font-weight: $fw-regular;
        width: 80%;
      }
    }

    p {
      font-weight: $fw-semibold;
      text-align: left;
    }

    .final-text {
      border-top: solid 1px $primary-color;
      margin-top: 0px;
      padding-top: 1.5rem;
      width: 95%;
      text-align: center;
    }
  }
}

.bottom {
  position: absolute;
  bottom: 0;
  right: 0;
  margin-bottom: 20px;
  margin-right: 20px;
  opacity: 0.5;
}

//LOGIN FORM
.email:focus,
.password:focus {
  background-color: #ffffff38;
  border-radius: 10px;
  border: solid 3px $primary-color;
}

#login-form-content {
  text-align: center;
  width: 82vw;
  margin: 0 auto;
  font-size: $fs-extra-large;

  h1 {
    margin-top: 1.25rem;
    margin-bottom: 3.25rem;
  }

  h2 {
    font-family: $ff-static-component;
    font-weight: $fw-bold;
  }

  #login-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-left: 0.625rem;
    margin-bottom: 2.8125rem;

    div {
      width: 40rem;
      display: flex;
      flex-direction: column;
      padding: 0 1rem;

      p {
        font-weight: $fw-bold;
        margin: 0rem;
        margin-bottom: 0.875rem;
        text-align: center;
      }

      .text-large {
        font-weight: $fw-bold;
        margin: 0rem;
        margin-bottom: 0.875rem;
        text-align: left;
      }

      input {
        width: 100%;
        height: 6.25rem;
        border: solid 2px $lighter-color;
        border-radius: 1.25rem;
        background-color: transparent;
        text-align: center;
        font-family: $ff-primary;
        font-size: $fs-extra-large;
        font-weight: $fw-bold;
        color: $lighter-color;
        text-decoration: none;
        padding: 1rem;

        @include focusing {
          outline: none;
          background-color: $lighter-color;
          color: $darker-color;
        }
      }
    }

    button {
      height: 6.25rem;
      width: 100%;
      padding: 1.625rem 0rem;
    }

    .email {
      border: solid 3px transparent;
      padding: 0.5rem 1rem;

      .login-input-error {
        background-color: $input-alert-color;
        border: solid 2px $alert-color;
      }


    }

    .login-error {
      height: 2.375rem;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      font-size: $fs-large;
      margin-left: 1rem;


      div {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        font-size: $fs-large;
        padding: 0;

        i {
          color: $alert-color;
          line-height: auto;
          margin-right: 0.6rem;

          &::before {
            vertical-align: 0.5em;
          }
        }
      }


    }

    .password {
      border: solid 3px transparent;
      height: auto;
      position: relative;
      padding: 0.5rem 1rem;

      .login-input-error {
        background-color: $input-alert-color;
        border: solid 2px $alert-color;
      }
    }

    #show-pass {
      display: flex;
      flex-direction: row;
      justify-content: left;
      outline: none;
      padding-bottom: 2rem;
      margin-left: 1rem;

      i {
        margin-right: 1rem;
      }

      .invalid-credentials {
        color: $alert-color;
        font-size: $fs-large;
        font-weight: bold;
        text-shadow: $ts-detail;
      }

      img {
        margin-right: 2.5rem;
      }

      @include focusing {
        color: $primary-color;
      }
    }

    i {
      margin-right: 1rem;
    }

    .invalid-credentials-wrapper {
      margin-bottom: 2rem;
      height: 2rem;
    }

    .invalid-credentials {
      color: $alert-color;
      font-size: $fs-large;
      font-weight: bold;
      text-shadow: $ts-detail;
    }

  }

  #login-button {
    border-radius: $br-60;
    transition: background-color 0.2s;
  }

  p:first-of-type {
    font-weight: $fw-bold;
  }
}
