//SEASONS

.season {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 23rem;
  margin-bottom: 0.5rem;

  .quaternary-button {
    width: 100%;
  }

  .button {
    width: 100%;
    border-radius: 0.5rem;

    .content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      &__season {
        color: var(--fg-neutral);
      }

      &__chapters {
        color: var(--fg-neutral-weak);
      }
    }

    &:hover {
      .content {
        &__season {
          color: var(--fg-neutral-inverse);
        }

        &__chapters {
          color: var(--fg-neutral-inverse-weak);
        }
      }
    }
  }

  &.selected-season {
    .button {
      background: $neutral-button-bg-color-hover;

      .content {
        &__season {
          color: var(--fg-neutral-inverse);
        }

        &__chapters {
          color: var(--fg-neutral-inverse-weak);
        }
      }
    }
  }
}


//CHAPTERS
#selector {
  height: auto;
  overflow: hidden;

  .selector-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;

  }
}

.chapter {
  opacity: 0.75;
  height: 13rem;
  display: flex;
  margin-bottom: 2.5rem;

  &:focus-visible {
    outline: none;
  }

  @include focusing {
    opacity: 1;

    .info {
      .number-of-chapter {
        color: $description-color;
      }

      .title {
        color: $lighter-color;
      }

      .description {
        color: $lighter-color;
      }
    }

    .thumb {
      img {
        outline: 2px solid var(--fg-neutral-strong);
        outline-offset: -2px;
      }
    }
  }

  .thumb {
    position: relative;

    img {
      border-radius: $hc-thumb-border-radius;
      height: 100%;
      width: 21.875rem;
      padding: 6px;
      object-fit: cover;
    }

    .progress-marker {
      position: absolute;
      left: 0;
      bottom: 6px;
      width: 100%;
      padding: 0 6px;

      &__container {
        width: 100%;
        background-color: var(--neutral-weak);
        border-bottom-right-radius: 4px;
      }

      &__line {
        background-color: var(--fg-primary);

        &--right-border {
          border-bottom-right-radius: 4px;
        }
      }

      &__container,
      &__line {
        height: 0.5rem;
        border-bottom-left-radius: 4px;
      }
    }
  }


  .info {
    flex: 1;
    width: 50rem;
    margin-left: 2rem;
    display: flex;
    flex-direction: column;

    &__header {
      display: flex;
      justify-content: space-between;
      align-items: baseline;
    }

    .number-of-chapter {
      font-size: $fs-medium;
      color: $description-color;
    }

    .title {
      font-size: $fs-extra-large;
      line-height: $lh-small;
      font-weight: $fw-semibold;
      color: $description-color;
      padding-bottom: 1rem;
    }

    .duration {
      font-weight: bold;
      font-size: $fs-medium ;
      color: var(--fg-neutral-weak);
    }

    .description {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      line-clamp: 3;
      overflow: hidden;
      font-size: $fs-large;
      color: $description-color;
    }
  }
}