.vis-timeline {
  --current-time-color: #F73F3F;
  border: none;
}

.vis-labelset {
  width: 12rem;

  .vis-label {
    padding-right: 0.5rem;
    // height: 6.375rem !important;

    .vis-inner {
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      background-color: var(--neutral-subtle-surface);
      border-radius: 8px;
      border: 4px solid var(--neutral-moderate-surface);

      img {
        width: 100%;
      }
    }
  }

}

.vis-panel {
  &.vis-left {
    border: none;
  }

  &.vis-center {
    border: none;
  }

  &.vis-bottom,
  &.vis-top {
    border: none;
  }
}

.vis-item.vis-range {
  background-color: var(--neutral-subtle-surface);
  border: 3px solid transparent;
  border-radius: 2px;

  &.vis-selected {
    background-color: var(--primary-color);

    &.vis-current-event {
      background-color: var(--primary-color);
      border-color: var(--primary-color);
    }
  }

  &.vis-current-event {
    background-color: var(--neutral-moderate-surface);
    color: var(--lighter-color);

    .item-property {
      color: var(--neutral-contrast-on-surface);
    }
  }


  .vis-item-content {
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 6px;
    justify-content: center;

    .item-property {
      display: block;
      color: var(--lighter-color);

      &__title {
        font-size: 1.25rem;
        font-weight: 700;
      }
    }
  }
}

.vis-current-time {
  background-color: var(--current-time-color);
  border-radius: 4px;
  width: 4px;
  box-shadow: 0px 0px 14px 5px #00000040;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  &__label {
    background-color: var(--current-time-color);
    padding: 0.2rem 0.5rem;
    font-weight: bold;
    border-radius: 2px;
  }
}

.vis-time-axis {
  .vis-minor {
    color: var(--neutral-light);
    font-weight: 600;

    &.vis-grid {
      border-color: var(--preloader-color);
    }
  }

  .vis-major.vjs-grid {
    display: none;
  }
}

div.vis-tooltip {
  max-width: 30rem;
  font: inherit;
  border: none;
  white-space: wrap;
  background-color: #fff;
  padding: 10px;
  border-radius: 4px;

  &__title {
    font-weight: bold;
  }
}

.vis-time-axis .vis-grid.vis-vertical {
  border-left: 2px solid var(--neutral-subtle-surface);
}