@import "./styles/colors";
@import "./styles/fonts";

// Define for GRIDS (HC THUMBNAILS
$hc-thumb-grid-filter: var(--hc-thumb-grid-filter);

// Define HOME CAROUSEL (HC THUMBNAILS
$hc-thumb-filter: var(--hc-thumb-filter);
$hc-thumb-border-radius: var(--hc-thumb-border-radius);
$hc-focus-border-radius: var(--hc-focus-border-radius);

// HOME CAROUSEL (HC) THUMBNAILS HEIGHT
$hc-thumb-height: var(--hc-thumb-height);
$hc-thumb-width: var(--hc-thumb-width);

// Define Gradients
$bottom-linear-gradient: var(--bottom-linear-gradient);
$right-linear-gradient: var(--right-linear-gradient);
$bottom-darker-linear-gradient: var(--bottom-darker-linear-gradient);
$right-darker-linear-gradient: var(--right-darker-linear-gradient);

// Define BORDER RADIUS
$br-05: var(--br-05);
$br-10: var(--br-10);
$br-25: var(--br-25);
$br-60: var(--br-60);

$landing-image-size: var(--landing-image-size);


//****************
//    COLORS
//****************

$primary-color: var(--primary);
$secondary-color: var(--primary);
$third-color: var(--primary);
$fourth-color: var(--primary);
$dark-color: var(--netral-sunken);
$dark-grey-color: var(--dark-grey-color);
$darker-color: var(--netral-sunken);
$lighter-color: var(--fg-neutral);
$description-color: var(--fg-neutral-strong);
$medium-grey: var(--medium-grey);

/* BACKGROUND COLORS */
$app-background: var(--neutral-weakest);
$menu-background: var(--neutral-weaker);
$foco-item-menu: var(--neutral-weak);

/* TRANSPARENT COLORS */
$primary-color-semi: var(--neutral-weak);
$lighter-color-semi: var(--neutral-strong);
$login-color-semi: var(--login-color-semi);
$onboarding-color-semi: var(--onboarding-color-semi);
$dark-color-semi: var(--dark-color-semi);

/* GRADIENT COLORS */
$black-gradient: var(--black-gradient);
$black-gradient-semi: var(--black-gradient-semi);
$transparent-gradient: var(--transparent-gradient);
$primary-gradient: var(--primary-gradient);
$primary-gradient-h: var(--primary-gradient-h);
$transparent: var(--transparent);

/* GENERAL TEXT SHADOW */
$ts-detail: var(--ts-detail);

// LEGAL DISPLAY
$float-panel: var(--float-panel);

/* SUBSCRIPTION TEXT COLOR */
$subscription-color: var(--subscription-color);

/* RIBBON NUMBER COLOR */
$ribbon-number-color: var(--ribbon-number-color);

/* MODAL BACKGROUND COLOR */
$modal-background-color: var(--neutral-weaker);
$modal-color-semi: var(--modal-color-semi);

/* PEGI NUMBER COLOR */
$pegi-number-color: var(--pegi-number-color);

/* SUBSCRIPTION AND PPV ICON COLOR */
$tag-icon-rectangular-color: var(--tag-icon-rectangular-color);
$tag-background-rectangular-color: var(--tag-background-rectangular-color);
$tag-icon-color: var(--tag-icon-color);
$tag-text-color: var(--tag-text-color);
$tag-background-color: var(--tag-background-color);

// BUTTONS FONT AND BACKGROUND COLORS
$button-font-color: var(--button-font-color);
$button-font-color-hover: var(--button-font-color-hover);
$button-detail-color-hover: var(--button-detail-color-hover);

//PRIMARY BUTTONS
$primary-button-text-color: var(--fg-neutral);
$primary-button-bg-color: var(--primary-weak);
$primary-button-border: var(--primary-weak);
$primary-button-outline: var(--primary-weak);

$primary-button-text-color-hover: var(--fg-on-primary);
$primary-button-bg-color-hover: var(--primary);
$primary-button-border-hover: var(--primary);
$primary-button-outline-hover: var(--primary);

//SECONDARY BUTTONS
$secondary-button-text-color: var(--fg-neutral);
$secondary-button-bg-color: var(--neutral-weak);
$secondary-button-border: var(--neutral-weak);
$secondary-button-outline: var(--neutral-weak);

//TERTIARY BUTTONS
$tertiary-button-text-color: var(--fg-neutral);
$tertiary-button-bg-color: var(--transparent);
$tertiary-button-border: var(--fg-neutral);
$tertiary-button-outline: var(--transparent);

//quaternary BUTTONS
$quaternary-button-text-color: var(--fg-neutral);
$quaternary-button-bg-color: var(--transparent);
$quaternary-button-border: var(--transparent);
$quaternary-button-outline: var(--transparent);

//NEUTRAL HOVER BUTTONS
$neutral-button-text-color-hover: var(--fg-neutral-inverse);
$neutral-button-bg-color-hover: var(--neutral-strongest);
$neutral-button-border-hover: var(--neutral-stronger);
$neutral-button-outline-hover: var(--neutral-stronger);

//PLYERUI BUTTONS
$neutral-buttonUI-bg: var(--neutral-stronger-10);
$neutral-buttonUI-bg-hover: var(--neutral-stronger);
$neutral-buttonUI-text-color: var(--fg-neutral);
$neutral-buttonUI-text-color-hover: var(--fg-neutral-inverse);

/* AlERTS COLORS */
$alert-color: var(--alert-color);
$input-alert-color: var(--input-alert-color);

/* ITEM BORDERS COLORS AND GRADIENTS */
$sidemenu-background-color: var(--sidemenu-background-color);
$sidemenu-border-right-size: var(--sidemenu-border-right-size);
$sidemenu-border-right-background: var(--sidemenu-border-right-background);
$sidemenu-closed-border-right-size: var(--sidemenu-closed-border-right-size);
$item-border-bottom-background: var(--item-border-bottom-background);

/* CATALOG-SELECTOR CARDS COLORS */
$catalog-selector-card-background: var(--catalog-selector-card-background);
$catalog-selector-card-background-hover: var(--catalog-selector-card-background-hover);
$catalog-selector-card-box-shadow-hover: var(--catalog-selector-card-box-shadow-hover);

$tab-text-color: var(--tab-text-color);
$tab-underline-color: var(--tab-underline-color);

/* NEW DESIGN SYSTEM */
$neutral-subtle-surface: var(--neutral-subtle-surface);
$neutral-moderate-surface: var(--neutral-moderate-surface);
$neutral-inverse-surface: var(--neutral-inverse-surface);

//****************
//      FONTS
//*****************

/* Define a GENERAL Typographies settings */
$ff-primary: var(--ff-primary);
$ff-secondary: var(--ff-secondary);
$ff-titles: var(--ff-titles);
$ff-static-component: var(--ff-static-component);

/* Define font color */
$fc-neutral: var(--fg-neutral);

/* Define a HEADERS LEVEL 2 Typographies settings */
$ff-title: var(--ff-title);

/* Define KEYBOARD font */
$ff-keyboard: var(--ff-keyboard);
$fs-keyboard: var(--fs-keyboard);

/* Define a GENERAL WEIGHT TYPOGRAPHY settings */
$fw-regular: var(--fw-regular);
$fw-medium: var(--fw-medium);
$fw-semibold: var(--fw-semibold);
$fw-bold: var(--fw-bold);
$fw-extrabold: var(--fw-extrabold);
$fw-titles: var(--fw-titles);

/* Define a GENERAL SIZE TYPOGRAPHY settings */
$fs-extra-small: var(--fs-extra-small);
$fs-small: var(--fs-small);
$fs-normal: var(--fs-normal);
$fs-medium: var(--fs-medium);
$fs-large: var(--fs-large);
$fs-extra-large: var(--fs-extra-large);
$fs-medium-large-title: var(--fs-medium-large-title);
$fs-extra-large-title: var(--fs-extra-large-title);

/* Define a LINE HEIGHT: */
$lh-small: var(--lh-small);
$lh-medium: var(--lh-medium);
$lh-large: var(--lh-large);

/* Define RETURN BUTTON FONT */
$fs-return-button: var(--fs-return-button);

/* Define LOGIN PAGE FONTS */
$fs-code-url: var(--fs-code-url);
$fs-login-code: var(--fs-login-code);
$fs-button-medium: var(--fs-button-medium);
$fs-button-large: var(--fs-button-large);
$fs-login-large: var(--fs-login-large);
$fs-login-medium: var(--fs-login-medium);

/* Define ENTERPRISE TITLE */
$enterprise-title-wrap: var(--enterprise-title-wrap);