:root {
  --primary-color: #01c6fd;
  ;
  --secondary-color: #1AFFFF;
  --third-color: #00FFFF;
  --fourth-color: #00d4bd;
  --dark-color: #000000BF;
  --dark-grey-color: #171B28;
  --darker-color: #000000;
  --lighter-color: #E6E6E6;
  --description-color: rgba(255, 255, 255, 0.6);
  --medium-grey: rgba(255, 255, 255, 0.302);

  /*BACKGROUND COLORS */
  --primary: #5BC3F7;
  --primary-weak: #00507C;
  --neutral-strongest: #F8FCFF;
  --neutral-stronger: rgb(231, 247, 255);
  --neutral-stronger-10: rgba(231, 247, 255, 0.1);
  --neutral-strong: #A6CBE1;
  --neutral: #7095A8;
  --neutral-weak: #384E60;
  --neutral-weaker: rgba(34, 47, 60, 0.95);
  --neutral-weakest: #080b11;
  --netral-sunken: #000000;

  /*FOREGROUND COLORS */
  --fg-on-primary: #001136;
  --fg-primary: #5BC3F7;
  --fg-neutral-strong: #F8FCFF;
  --fg-neutral: #E3F3FF;
  --fg-neutral-weak: #B5D9F0;
  --fg-neutral-weaker: #97BCD1;
  --fg-neutral-inverse: #222F3C;
  --fg-neutral-inverse-weak: #4F7084;

  /* TRANSPARENT COLORS */
  --primary-color-semi: rgba(0, 255, 255, 0.2);
  --lighter-color-semi: rgba(255, 255, 255, 0.5);
  --login-color-semi: rgba(0, 0, 0, 0.4);
  --onboarding-color-semi: rgba(0, 0, 0, 0.4);
  --transparent: rgba(0, 0, 0, 0.0);
  --dark-color-semi: rgba(8, 0, 27, 0.8);

  /* GRADIENT COLORS */
  --black-gradient: rgba(0, 0, 0, 0);
  --black-gradient-semi: rgba(0, 0, 0, 0.5);
  --transparent-gradient:var(--neutral-weakest);
  --primary-gradient: linear-gradient(180deg, var(--primary-color) 0%, var(--fourth-color) 100%);
  --primary-gradient-h: linear-gradient(90deg, var(--primary-color) 0%, var(--fourth-color) 100%);

  /* GENERAL TEXT SHADOW */
  --ts-detail: 1px 1px 2px var(--darker-color);

  /* LEGAL DISPLAY */
  --float-panel: #2E3235;

  /* SUBSCRIPTION TEXT COLOR */
  --subscription-color: var(--lighter-color);

  /* RIBBON NUMBER COLOR */
  --ribbon-number-color: var(--dark-grey-color);

  /* MODAL BACKGROUND COLOR */
  --modal-background-color: #2E3235;
  --modal-color-semi: var(--black-gradient-semi);

  /* PEGI NUMBER COLOR */
  --pegi-number-color: var(--dark-grey-color);

  /* SUBSCRIPTION AND PPV ICON COLOR */
  --tag-icon-rectangular-color: var(--darker-color);
  --tag-background-rectangular-color: var(--primary-gradient);
  --tag-icon-color: var(--darker-color);
  --tag-text-color: var(--darker-color);
  --tag-background-color: var(--primary-color);

  /* BUTTONS FONT AND BACKGROUND COLORS */
  --button-font-color: var(--lighter-color);
  --button-font-color-hover: var(--darker-color);
  --button-detail-color-hover: var(--primary-gradient);

  --primary-button-text-color: var(--lighter-color);
  --primary-button-bg-color: var(--transparent);
  --primary-button-border: solid 2px var(--primary-color);
  --primary-button-outline: none;

  --primary-button-text-color-hover: var(--lighter-color);
  --primary-button-bg-color-hover: var(--primary-gradient);
  --primary-button-border-hover: none;
  --primary-button-outline-hover: none;
  --primary-box-shadow-hover: 0 0 0 4px var(--primary-color);

  --secondary-button-text-color: var(--lighter-color);
  --secondary-button-bg-color: var(--medium-grey);
  --secondary-button-border: none;
  --secondary-button-outline: solid 2px var(--transparent);

  --secondary-button-text-color-hover: var(--darker-color);
  --secondary-button-bg-color-hover: var(--lighter-color);
  --secondary-button-border-hover: none;
  --secondary-button-outline-hover: none;
  --secondary-box-shadow-hover: 0 0 0 6px var(--lighter-color);

  --play-button-text-color: var(--lighter-color);
  --play-button-text-color-hover: var(--lighter-color);

  --return-button-color: var(--primary-button-text-color-hover);
  --return-button-color-border: solid 2px var(--primary-button-text-color-hover);
  --return-button-color-bg: transparent;

  --return-button-color-hover: var(--primary-button-text-color-hover);
  --return-button-color-border-hover: solid 2px var(--primary-button-text-color-hover);
  --return-button-color-bg-hover: var(--primary-button-text-color-hover);

  /* AlERTS COLORS */
  --alert-color: #f44336;
  --input-alert-color: #f4433650;

  /* ITEM BORDERS COLORS AND GRADIENTS */
  --sidemenu-background-color: var(--neutral-weaker);
  --sidemenu-border-right-size: 4px solid;
  --sidemenu-border-right-background: var(--primary-gradient);
  --sidemenu-closed-border-right-size: 4px solid;
  --item-border-bottom-background: var(--primary-gradient-h);

  /* CATALOG-SELECTOR CARDS COLORS */
  --catalog-selector-card-background: #1D1A21;
  --catalog-selector-card-background-hover: #000000;
  --catalog-selector-card-box-shadow-hover: 0 0 0 4px #FFFFFF;

  --tab-text-color: var(--lighter-color);
  --tab-underline-color: var(--lighter-color);

  /* Define for GRID (HC THUMBNAILS) */
  --hc-thumb-grid-filter: brightness(0.35);

  /* Define HOME CAROUSEL (HC THUMBNAILS) */
  --hc-thumb-filter: brightness(0.4);
  --hc-thumb-border-radius: 0.625rem;
  --hc-focus-border-radius: 1.25rem;

  /* HOME CAROUSEL (HC) THUMBNAILS HEIGHT */
  --hc-thumb-height: 10rem;
  --hc-thumb-width: 17.5rem;

  /* Define Gradients */
  --bottom-linear-gradient: linear-gradient(to bottom, var(--black-gradient) 20%, var(--transparent-gradient));
  --right-linear-gradient: linear-gradient(to right, var(--black-gradient) 20%, var(--transparent-gradient));
  --bottom-darker-linear-gradient: linear-gradient(to bottom, var(--black-gradient-semi) 1%, var(--transparent-gradient));
  --right-darker-linear-gradient: linear-gradient(to right, var(--black-gradient-semi) 1%, var(--transparent-gradient));

  /* Define BORDER RADIUS */
  --br-05: 0.3125rem;
  --br-10: 0.625rem;
  --br-25: 1.25rem;
  --br-60: 3.75rem;

  --landing-image-size: 20.263rem;

  /* NEW DESIGN SYSTEM */
  --neutral-subtle-surface: #222F3C;
  --neutral-moderate-surface: #384E60;
  --neutral-inverse-surface: #F8FCFF;
}
