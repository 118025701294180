//CSS FOR BUTTON COMPONENT IN VIDEO DETAIL & DETAIL COMPONENT //
.button-list {
  display: flex;
  justify-content: left;

  .button-component {
    display: flex;
    flex-shrink: 0;

    .button-wrapper {
      margin-right: 0.75rem;
    }
  }
}

.button {
  height: 100%;
  border-radius: $br-60;
  padding: 0.75rem 1.5rem;

  .content {
    display: flex;
    align-items: center;
    justify-content: center;

    .icon {
      display: flex;
      justify-content: center;
      text-align: center;
      height: 2rem;
      width: 2rem;

      &--space {
        margin-right: 0.75rem;
      }
    }
  }
}

.ui-button {
  border-radius: $br-60;
  padding: 1rem;
  white-space: nowrap;

  .content {
    display: flex;
    align-items: center;
    justify-content: start;
    height: 3rem;


    .icon {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      height: 2rem;
      width: 2rem;

      &--space {
        margin: 0.5rem;
      }
    }

    .text {
      margin-right: 0.25rem;
    }
  }
}

.primary-ui-button {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;


  .tooltip {
    position: absolute;
    justify-content: center;
    align-items: center;
    bottom: 12rem;
    white-space: nowrap;
    overflow: visible;
    font-size: $fs-large;
    font-weight: $fw-regular;
    color: $secondary-button-text-color;
    margin-bottom: 0.5rem;
    display: none;
  }

  .ui-button {
    background-color: $neutral-buttonUI-bg;
    color: $neutral-buttonUI-text-color;
    border: none;

    &--disabled {
      background-color: var(--neutral-weak);
      opacity: .5;
      border: none;
    }
  }

  @include focusing {
    outline: none;

    .tooltip {
      display: flex;
    }

    .ui-button {
      background-color: $neutral-buttonUI-bg-hover;
      color: $neutral-buttonUI-text-color-hover;

      &--disabled {
        background: var(--neutral-weak);
        opacity: .7;
        border: none;
      }
    }
  }
}

.primary-button {
  .button {
    color: $button-font-color;
    background-color: $primary-button-bg-color;
    border: 0.125rem solid $primary-button-bg-color;
    outline: $primary-button-outline;

    &--disabled {
      background-color: var(--neutral-weak);
      opacity: .5;
      border: none;
    }
  }

  @include focusing {
    outline: none;

    .button {
      background: $primary-button-bg-color-hover;
      color: $primary-button-text-color-hover;
      border: 0.125rem solid $primary-button-border-hover;
      outline: $primary-button-outline-hover;

      &--disabled {
        background: var(--neutral-weak);
        opacity: .7;
        border: none;
      }
    }
  }
}

.secondary-button {
  button {
    background: $secondary-button-bg-color;
    color: $secondary-button-text-color;
    border: 0.125rem solid $secondary-button-border;
    outline: $secondary-button-outline;

  }

  @include focusing {
    outline: none;

    button {
      background: $neutral-button-bg-color-hover;
      color: $neutral-button-text-color-hover;
      border: 0.125rem solid $neutral-button-border-hover;
      outline: $neutral-button-outline-hover;
    }
  }
}

.tertiary-button {
  button {
    background: $tertiary-button-bg-color;
    color: $tertiary-button-text-color;
    border: 0.125rem solid $tertiary-button-border;
    outline: $tertiary-button-outline;

  }

  @include focusing {
    outline: none;

    button {
      background: $neutral-button-bg-color-hover;
      color: $neutral-button-text-color-hover;
      border: 0.125rem solid $neutral-button-border-hover;
      outline: $neutral-button-outline-hover;
    }
  }
}

.quaternary-button {
  button {
    background: $quaternary-button-bg-color;
    color: $quaternary-button-text-color;
    border: 0.125rem solid $quaternary-button-border;
    outline: $quaternary-button-outline;

  }

  @include focusing {
    outline: none;

    button {
      background: $neutral-button-bg-color-hover;
      color: $neutral-button-text-color-hover;
      border: 0.125rem solid $neutral-button-border-hover;
      outline: $neutral-button-outline-hover;
    }
  }
}

.button-medium {
  font-family: $ff-primary;
  font-size: $fs-button-medium;
  font-weight: $fw-semibold;
}

.button-large {
  font-family: $ff-primary;
  font-size: $fs-button-large;
  font-weight: $fw-semibold;
}

.text-large {
  font-size: $fs-login-large;
  font-weight: $fw-regular;
}

.text-medium {
  font-size: $fs-login-medium;
  font-weight: $fw-regular;
}