.theo-primary-color,
.vjs-selected {
  color: var(--main-color) !important;
}

.theoplayer-container.theoplayer-skin .vjs-big-play-button {

  max-width: 5rem;
  height: 5rem;
  padding-bottom: 0;

  &:hover {
    background: none;
    -webkit-transition-duration: 0.2s;
    -moz-transition-duration: 0.2s;
    transition-duration: 0.2s;
    transform: translate(-50%, -50%) scale(1.1);
    transition: transform 0.2s;
  }
}

.theoplayer-container {
  background-color: black;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
}

.theo-player-wrapper {
  background-color: transparent !important;
}